<template>
  <div class="subpage">
    <section id="hero-group" class="container-limiter">
      <div id="hero">
        <TopGallery/>
      </div>
      <div id="hero-lower" class="container">
        <div class="main">
          <img src="../layout/logo-icon.svg"/>
          <div class="title">NARVIK</div>
          <div class="subtitle">micromilspec <span class="faded-dark">x</span> {{SAMARBEIDSPARTNER}}</div>
          <div class="yellow">Bestillingsperioden stenger 9. mars</div>
        </div>
        <div class="copy">
          <h3 class="yellow">SPESIALPRODUSERT FOR {{SAMARBEIDSPARTNER}}</h3>
          <h1>NARVIK er den offisielle klokken for 2. bataljon, utviklet av MICROMILSPEC i samarbeid med bataljonens ledelse.</h1>
          <p class="faded">
            Forbeholdt aktive soldater, vernepliktige, veteraner, spesielt inviterte og støttegrupper.
          </p>
          <p class="flex items-start my-8">
            <span class="mr-4 inline-block">
              <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8464 10.0194V8.83258H8.67883V10.0508H9.40987V14.4366H8.67883V15.6551H12.5774V14.4366H11.8464V10.0194Z" fill="#FAD348"/>
              <path d="M10.6284 8.07003C11.3012 8.07003 11.8466 7.5246 11.8466 6.85179C11.8466 6.17897 11.3012 5.63354 10.6284 5.63354C9.95558 5.63354 9.41016 6.17897 9.41016 6.85179C9.41016 7.5246 9.95558 8.07003 10.6284 8.07003Z" fill="#FAD348"/>
              <path d="M21.2568 10.6284C21.2568 4.76784 16.489 0 10.6284 0C4.76784 0 0 4.76784 0 10.6284C0 15.3545 3.1008 19.3678 7.37448 20.7454L10.6284 24L13.8826 20.7458C18.156 19.3678 21.2568 15.3547 21.2568 10.6284ZM10.6284 18.5146C6.28008 18.5146 2.74296 14.9762 2.74296 10.6286C2.74296 6.28056 6.28032 2.74296 10.6284 2.74296C14.9765 2.74296 18.5138 6.28056 18.5138 10.6286C18.5141 14.9762 14.9765 18.5146 10.6284 18.5146Z" fill="#FAD348"/>
              </svg>
            </span>
            <a href="#" class="yellow font-sm" @click.prevent="showPurchaseQualifications = !showPurchaseQualifications">Er klokken tilgjengelig for meg?</a>
          </p>
          <div v-if="showPurchaseQualifications">
            <p>NARVIK er kun tilgjengelig via denne nettsiden, og kan bestilles av privatpersoner som:</p>
            <p>– Har eller har hatt en tilknytning til 2. bataljon som ansatt eller vernepliktig.</p>
            <p>– Har vært en del av en støtteavdeling under deployering eller øvelse.</p>
            <p>Innfrir du ett av disse kravene er du velkommen til å bestille klokken. Bestillingene kontrolleres i samarbeid med 2. bataljon, og du behøver derfor ikke å legge ved noen dokumentasjon på din relasjon til enheten. Kjøpere som ikke kvalifiserer vil få refundert sitt innbetalte beløp fortløpende.</p>
          </div>
          <p class="price">
            kr {{PRICE}},- / <span class="faded">kr {{DELBETALING_PRICE}} ved delbetaling</span>
          </p>
          <KjopLink/>
        </div>
        <div class="feature-list">
          <p>Swiss Made <span class="faded">- i tråd med århundrelange tradisjoner.</span></p>
          <FeatureList/>
        </div>
      </div>
    </section>

    <ScrollWidthBox background-color="#f6f9fd">
      <TreTidsoner/>
    </ScrollWidthBox>

    <Stiler/>

    <section id="kjop" class="container">
      <div class="inner">
        <KjopLink>
          Kjøp nå fra {{PRICE}},-
        </KjopLink>
      </div>
    </section>

    <InteractiveVisning/>

    <ScrollWidthBox>
      <Engraving/>
    </ScrollWidthBox>

    <ScrollWidthBox>
      <ExplodeSequence/>
    </ScrollWidthBox>

    <FeatureGrid/>

    <ScrollWidthBox>
      <Straps/>
    </ScrollWidthBox>

    <Garanti/>
    <Boksen/>

    <FixedCTA/>
  </div>
</template>

<script>
import FixedCTA from "~/components/ui/FixedCTA";
import ScrollWidthBox from "~/components/ui/ScrollWidthBox";
import KjopLink from "~/components/ui/KjopLink";

import TopGallery from "./TopGallery/TopGallery.vue";
import Stiler from "./Stiler/Stiler.vue";
import TreTidsoner from "./TreTidsoner/TreTidsoner.vue";
import Rotor from "./Rotor/Rotor.vue";
import Straps from "./Straps/Straps.vue";
import Engraving from "./Engraving/Engraving.vue";
import FeatureGrid from "./FeatureGrid/FeatureGrid.vue";
import ExplodeSequence from "./ExplodeSequence/ExplodeSequence.vue";
import InteractiveVisning from "./InteractiveVisning/InteractiveVisning.vue";
import Garanti from "./Garanti/Garanti.vue";
import Boksen from "./Boksen/Boksen.vue";
import FeatureList from "./FeatureList/FeatureList.vue";

import {PRICE, DELBETALING_PRICE, SAMARBEIDSPARTNER} from "~/data";

export default {
  name: "Klokken",
  data() {
    return {
      PRICE, 
      DELBETALING_PRICE, 
      SAMARBEIDSPARTNER,
      showPurchaseQualifications: false
    }
  },
  components: {
    TopGallery,
    FixedCTA,
    ScrollWidthBox,
    KjopLink,
    Stiler,
    TreTidsoner,
    Rotor,
    Straps,
    Engraving,
    FeatureGrid,
    ExplodeSequence,
    InteractiveVisning,
    Garanti,
    Boksen,
    FeatureList
  }
};
</script>

<style lang="scss" scoped>
@import "~/css/system.scss";

.flex {
  display: flex;
}

.items-start {
  justify-content: flex-start;
}

.mr-4 {
  margin-right: 4px;
}

.my-8 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.inline-block {
  display: inline-block;
}

.font-sm {
  font-size: 16px;
}

section#hero-group {
  position: relative;
  background-color: black;
  @include m {
    background: none;
  }
  z-index: 0;

  #hero {
    position: sticky;
    top: 0rem;
    z-index: -1;
    @include m {
      position: static;
    }
  }

  #hero-lower {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows:  auto;
    @include m {
      grid-template-columns: 1fr 1fr;
    }

    .main {
      @include m {
        grid-column-start: span 2;
        padding-bottom: 8.2rem;
      }
      margin-top: 6.43rem;
      .title {
        @include TextM30;
        @include m {
          @include TextD40;
        }
        margin-top: 1.37rem;
      }
      .subtitle {
        margin-top: .2rem;
        @include TextM16Uppercase;
        @include m {
          margin-top: .8rem;
          @include TextD20Uppercase;
        }
        span {
          font-size: 75%;
          padding: 0 .1rem;
        }
      }
      .yellow {
        @include TextD14;
        @include m {
          margin-top: .7rem;
        }
      }
    }

    .copy {
      margin-top: 4.68rem;
      .price {
        margin-top: 2.5rem;
        @include m {
          margin-top: 6.1rem;
          margin-bottom: 1rem;
        }
      }
      @include m {
        margin-top: 0;
      }
    }

    .feature-list {
      margin-top: 3.62rem;
      @include m {
        margin-top: 0;
        max-width: 24rem;
        justify-self: end;
      }
    }
  }
}

section#kjop {
  text-align: center;
  margin-top: $box-gap;
  @include m {
    background: none;
  }
  .inner {
    padding: 1rem;
    background-color: black;
  }
}

</style>
