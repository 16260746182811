<template>
  <div class="fixed-cta">
    <a :href="KJOP_URL">Kjøp nå →</a>
  </div>
</template>

<script>
import { KJOP_URL } from "../../data.js";

export default {
  name: "FixedCTA",
  data() {
    return { KJOP_URL };
  }
}
</script>

<style lang="scss" scoped>
@import "../../css/system.scss";

.fixed-cta {
  @include TextM14;
  z-index: 9999;
  position: fixed;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
  color: white;
  padding: .8rem;
  padding-top: .7rem;
  background: #ffd300;
  text-align: center;
  a {
    text-decoration: none;
    color: #000;
  }
  @include m {
    display: none;
  }
}
</style>
