<template>
  <div class="page container m-padded">
    <div class="columns">
      <div class="column left">
        <div id="hero-wrapper">
          <img src="./hero_b@2x.jpg" class="hero display-b"/>
          <img src="./hero_m@2x.jpg" class="hero display-m"/>
        </div>
      </div>

      <div ref="column-right" class="column right container b-padded">
        <div id="top-logos">
          <img src="./top-logo-b.svg"/>
          <img src="./top-logo-a.svg"/>
        </div>

        <h1>NARVIK er den første offisielle klokken for 2. bataljon. Klokken er utviklet av norske MICROMILSPEC, og produseres i Sveits i tråd med det sveitsiske Forbundsrådets strenge kriterier for “Swiss Made”.</h1>

        <p class="faded">
          NARVIK er en spesialprodusert klokke inspirert av 2. bataljons virke, identitet og historikk. En spesialdesignet klokke som gjør seg like godt til uniformen som et sivilt antrekk, og med skjult symbolikk for “de som vet”. Klokken er forbeholdt aktive soldater, vernepliktige, veteraner, spesielt inviterte og støttegrupper.
        </p>

        <p class="faded">
          Navnet “NARVIK” er en referanse til 2. bataljons rolle som tradisjonsbærer for flere underavdelinger tilhørende infanteriregiment 14, 15 og 16. Noen av disse underavdelingene var aktive på Narvikfronten, og i kampene for gjenerobringen av Narvik. Med navnet minnes og hyller vi hele seansen fra Lapphaugen, via fjellene og toppene til Narvik. De historiske kampene – Narvik, Roasme, HD 620 og HD 698, Storebalak – Næverfjell, Kobberfjell – Kuberget – er også inngravert på klokkens baklokk.
        </p>

        <p class="faded">
          Utviklingen av NARVIK har pågått i det stille siden sommeren 2020, og bygger på en tett dialog mellom MICROMILSPEC og ledelsen i 2. bataljon. Mange arbeidstimer, diskusjoner og designforslag senere, har resultert i en unik klokke dedikert til bataljonen.
        </p>

        <p class="faded">
          I samarbeid med noen av verdens fremste fabrikker og spesialister, skrives nå et nytt kapittel i norsk klokkehistorie. Etter endt bestillingsperiode kalibreres maskinparken i Sveits, og produksjonen av NARVIK er estimert til 180 dager.
        </p>
        
        <p class="faded">
          De omfattende prosessene er basert på århundrelange håndverkstradisjoner, hvor grove råmaterialer foredles til et raffinert mekanisk presisjonsinstrument spesialdesignet for 2. bataljon.
        </p>
        
        <p class="faded">
          NARVIK er produsert for å vare i generasjoner, og vil kunne vedlikeholdes og repareres i det nært uendelige.
        </p>
        
        <h1>Møt gamle kjente.</h1> 
        
        <p class="faded">
          Det er opprettet Facebook-grupper for å samle aktive soldater og veteraner med tilknytning til 2. bataljon. Gruppen eksisterer ene og alene med støtte fra medlemmenes eget engasjement og verving.
        </p>

        <div id="fb-container">
          <div class="fb-group"
             data-href="https://www.facebook.com/groups/1105321043247239"
             :data-width="fbWidth"
             data-show-social-context="true" 
             data-show-metadata="false"
             data-skin="dark">
          </div>
        </div>


      </div>
    </div>
    <FixedCTA/>
  </div>
</template>

<script>
import { KJOP_URL } from "../../data.js";
import RangeSequence from "../ui/RangeSequence";
import FixedCTA from "../ui/FixedCTA";

export default {
  data() {
    return {
      KJOP_URL,
      fbWidth: 310
    }
  },
  mounted() {
    window.addEventListener("resize", this.scaleFb);
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.scaleFb);
  },
  methods: {
    scaleFb() {
      //this doesn't work - but could it? The FB SDK rewrites many styles on mount
      this.fbWidth = this.$refs["column-right"].clientWidth;
    }
  },
  components: {RangeSequence, FixedCTA}
}
</script>

<style lang="scss" scoped>
@import "../../css/system";

p#swiss-made {
 margin-block-start: 3.2125rem;
 margin-block-end: 1.875rem;
}

.columns {
  display: flex;
  flex-direction: column;
  @include m {
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex-direction: column;
  @include m {
    width: 50%;
    margin-right: 7.5rem;
    &:last-child {
      margin-right: 0;
    }
  }

}

#hero-wrapper {
  position: sticky;
  top: 7.182rem;
  .hero {
    width: 100%;
    @include m {
    }
  }
}

#top-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.8125rem;
  margin-bottom: 1.75rem;
  @include m {
    margin-top: 10.31rem;
  }
  img {
    height: 4.37rem;
    margin-right: 1.25rem;
  }
}


</style>
