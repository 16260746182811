<template>
  <section class="container m-padded">
    <div class="grid">
      <MobileScrollWidthBox>
      <div class="item">
        <img src="./dial@2x.jpg"/>
        <h5>Urskiven er utsmykket med Griffen, infanterimerket og skispor som symboliserer bataljonens tilhørighet og historiske arv i nord.</h5>
      </div>
      </MobileScrollWidthBox>
      <MobileScrollWidthBox>
      <div class="item">
        <img src="./hands@2x.jpg"/>
        <h5 class="wide">Selvlysende visere inspirert av bajonetten på en AG-3. Bajonettdesignet viser infanteribataljonens evne og vilje til å møte motstanderen på kloss hold.</h5>
      </div>
      </MobileScrollWidthBox>
      <MobileScrollWidthBox>
      <div class="item">
        <img src="./rehaut@2x.jpg"/>
        <h5>Lasergravert ring med «2. bataljon Est. 1955 • 34W DB 3167 5882 • Mjøøød • In Hoc Signo Vinces • Strong and wise, swift and deadly • Maukeriket»</h5>
      </div>
      </MobileScrollWidthBox>
      <MobileScrollWidthBox>
      <div class="item">
        <img src="./narvik@2x.jpg"/>
        <h5>2. bataljon er tradisjonsbærer til flere underavdelinger (til spesielt IR15 og IR16), som var aktivt med i Narvikfronten og kampene i gjenerobringen av Narvik i 1940. Derav navnet «NARVIK».</h5>
      </div>
      </MobileScrollWidthBox>
    </div>
  </section>
</template>

<script>
import MobileScrollWidthBox from "~/components/ui/MobileScrollWidthBox"

export default {
  name: 'FeatureGrid',
  components: {MobileScrollWidthBox}
}
</script>

<style lang="scss" scoped>
@import "~/css/system.scss";

section {
  margin-top: .625rem;
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0.625rem;

    @include m {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 0.625rem;
    }

    .item {
      background-color: black;
      padding: 1.75rem 0rem;
      margin: 0rem $gutter;
      @include m {
        padding: 4.37rem 0rem;
        margin: 0rem 4.37rem;
      }
      text-align: center;

      h1 {
        text-align: center;
        width: 80%;
        margin: 0px;
        padding: 0px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1.5rem;
        margin-bottom: .9rem;
        @include m {
          width: 80%;
          &.wide {
            width: 100%;
          }
          margin-top: 2.7rem;
          margin-bottom: 0;
        }
      }

      h5 {
        font-weight: normal;
      }

      img {
        width: 80%;
        @include m {
          width: 95%;
        }
      }
    }
  }
}
</style>
